export default null

export const COST_AND_USAGE = "Cost & Usage Report"
export const INVENTORY = "Inventory Report"
export const TRIAL_BALANCE = "Trial Balance Report"
export const GLOBAL_DATA = "Global Data Export"
export const UNIVERSAL_DATA = "Universal Data Export"
export const ACCRUAL = "Accrual Report"
export const FACILITIES = "Facilities Report"
export const MONTH_END_REPORT = "Month End Report"
