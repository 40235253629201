import React from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Layout, Menu, Dropdown } from "antd"
import { SearchOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons"

import { selectScopedClient, selectScopedByClient } from "../../stores/appSlice"
import { selectClient } from "../clients/clientsSlice"
import HeaderDropdown from "./HeaderDropdown"
import AlertPanel from "./AlertPanel"
import MucLogoHorizontal from "../../components/utilityComponents/assetComponents/MucLogoHorizontal"

import Role from "../userRoles/Role"
import Settings from "./Settings"

const financialsMenu = (scopedByClient, client, highlightTab) => (
    <Menu>
        {client && client.accounting_batch_management && (
            <Role action="read" model="accounting_batch">
                <Menu.Item>
                    <Link to={scopedByClient ? `/clients/${scopedByClient}/accounting_batches` : "/"}>Client Batches</Link>
                </Menu.Item>
            </Role>
        )}

        {client && client.utility_payment_management && (
            <Role action="read" model="payment_batch">
                <Menu.Item>
                    <Link to={scopedByClient ? `/clients/${scopedByClient}/payment_batches` : "/"}>Vendor Batches</Link>
                </Menu.Item>
            </Role>
        )}

        {client && client.ledger_account_management && (
            <>
                <Role action="read" model="ledger_field">
                    <Menu.Item>
                        <Link to={scopedByClient ? `/clients/${scopedByClient}/ledger_fields` : "/"}>Ledger Fields</Link>
                    </Menu.Item>
                </Role>

                <Role action="read" model="ledger_account">
                    <Menu.Item>
                        <Link to={scopedByClient ? `/clients/${scopedByClient}/ledger_accounts` : "/"}>Ledger Accounts</Link>
                    </Menu.Item>
                </Role>
            </>
        )}
    </Menu>
)

const groupAccountsMenu = (scopedByClient, highlightTab) => (
    <Menu>
        <Menu.Item>
            <Link to={scopedByClient ? `/clients/${scopedByClient}/group_accounts` : "/"}>Group Billing Accounts</Link>
        </Menu.Item>

        <Menu.Item>
            <Link to={scopedByClient ? `/clients/${scopedByClient}/group_supply_accounts` : "/"}>Group Billing Supply Accounts</Link>
        </Menu.Item>
    </Menu>
)

const alertsPanelMenu = (scopedByClient, highlightTab) => (
    <Menu>
        <Menu.Item>
            <Link to={scopedByClient ? `/clients/${scopedByClient}/alerts/billable_accounts_missing_bills` : "/"}>Billable Accounts W/O Bills Past 30 Days</Link>
        </Menu.Item>

        <Menu.Item>
            <Link to={scopedByClient ? `/clients/${scopedByClient}/alerts/supply_accounts_missing_bills` : "/"}>Supply Accounts W/O Bills Past 30 Days</Link>
        </Menu.Item>

        <Menu.Item>
            <Link to={scopedByClient ? `/clients/${scopedByClient}/alerts/billable_accounts_consecutive_estimations` : "/"}>Billable Accounts With Three Consecutive Estimated Reads</Link>
        </Menu.Item>

        <Menu.Item>
            <Link to={scopedByClient ? `/clients/${scopedByClient}/alerts/past_due_bills` : "/"}>Bills 30+ Days Past Due</Link>
        </Menu.Item>
    </Menu>
)

const clientHasFinancialsAccess = (client) => {
    return client && (client.accounting_batch_management || client.utility_payment_management || client.ledger_account_management)
}

export default function Nav({ highlightTab, landingPage }) {
    const scopedByClient = useSelector(selectScopedByClient)
    const client = useSelector(selectClient)
    const { clientId } = useParams()
    // @TODO: I think if we scope by the react router, this will load faster and not glitch out the nav
    // from the admin to the client while it's waiting for the client to load, etc... for this purpose
    // i think if there is a client id in the params we can treat it as scoped by client...
    // const scopedByClient = clientId

    // Why is this being fired off even when scopedByClient isn't changing?
    // Something is causing this entire component to completely rerender
    // when the page changes and I think it has something to do with the router.
    // useEffect(() => {
    //     if (scopedByClient && clientId !== scopedByClient) {
    //         setClientId(scopedByClient)
    //         history.push(`/clients/${scopedByClient}/dashboard`)
    //     }
    // }, [scopedByClient])

    return (
        <Layout.Header id="navigation-header" style={{ position: "relative" }}>
            <div id="nav-logo-collection">
                <div id="channel-partner-logo" className="navigation-logo full-wh">
                    <MucLogoHorizontal />
                </div>
            </div>
            <div id="header-nav-action-container">
                {!landingPage && (
                    <div id="header-search">
                        <div className="search-icon">
                            <div className="search-trigger">
                                <SearchOutlined />
                            </div>
                            <input type="text" id="header-search-field" name="header-search" />
                            <CloseOutlined />
                        </div>
                    </div>
                )}

                <div id="navigation-buttons">
                    <ul id="nav-links">
                        {/* TODO: Refactor this as a function or different component for admin/client based nav */}

                        {landingPage && <h2 style={{ marginTop: "20px" }}>Please select a client from the dropdown</h2>}

                        {!scopedByClient && !landingPage && (
                            <Role admin={true}>
                                <li>
                                    <Link to="/" role="button" className={`${highlightTab === "home" ? "current" : ""}`}>
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/clients"} className={`${highlightTab === "clients" ? "current" : ""}`}>
                                        Clients
                                    </Link>
                                </li>
                                <Role action="read" model="user">
                                    <li>
                                        <Link to={"/users"} className={`${highlightTab === "users" ? "current" : ""}`}>
                                            Users
                                        </Link>
                                    </li>
                                </Role>
                                <Role action="read" model="channel_partner">
                                    <li>
                                        <Link to={"/channel_partners"} className={`${highlightTab === "channelPartners" ? "current" : ""}`}>
                                            Channel Partners
                                        </Link>
                                    </li>
                                </Role>
                                <Role action="read" model="vendor">
                                    <li>
                                        <Link to={"/vendors"} className={`${highlightTab === "vendors" ? "current" : ""}`}>
                                            Vendors
                                        </Link>
                                    </li>
                                </Role>
                                <Role action="read" model="utility_service">
                                    <li>
                                        <Link to={"/utility_services"} className={`${highlightTab === "utilityServices" ? "current" : ""}`}>
                                            Utility Services
                                        </Link>
                                    </li>
                                </Role>
                                <Role action="read" model="setting">
                                    <li>
                                        <Link to={"/settings/audit_log"} className={`${highlightTab === "settings" ? "current" : ""}`}>
                                            Settings
                                        </Link>
                                    </li>
                                </Role>
                            </Role>
                        )}

                        {scopedByClient && !landingPage && (
                            <>
                                <Role action="read" model="client">
                                    <li>
                                        <Link to={`/clients/${scopedByClient}/dashboard`} role="button" className={`${highlightTab === "home" ? "current" : ""}`}>
                                            Home
                                        </Link>
                                    </li>
                                </Role>
                                <Role action="read" model="division">
                                    <li>
                                        <Link to={scopedByClient ? `/clients/${scopedByClient}/divisions/dashboard` : "/divisions"} className={`${highlightTab === "divisions" ? "current" : ""}`}>
                                            Divisions
                                        </Link>
                                    </li>
                                </Role>
                                <Role action="read" model="facility">
                                    <li>
                                        <Link to={scopedByClient ? `/clients/${scopedByClient}/facilities/dashboard` : "/facilities"} className={`${highlightTab === "facilities" ? "current" : ""}`}>
                                            Facilities
                                        </Link>
                                    </li>
                                </Role>
                                <Role action="read" model="billable_account">
                                    <li>
                                        <Link
                                            to={scopedByClient ? `/clients/${scopedByClient}/billable_accounts` : "/billable_accounts"}
                                            className={`${highlightTab === "billableAccounts" ? "current" : ""}`}>
                                            Billable Accounts
                                        </Link>
                                    </li>
                                </Role>
                                <Role action="read" model="supply_account">
                                    <li>
                                        <Link
                                            to={scopedByClient ? `/clients/${scopedByClient}/supply_accounts` : "/supply_accounts"}
                                            className={`${highlightTab === "supplyAccounts" ? "current" : ""}`}>
                                            Supply Accounts
                                        </Link>
                                    </li>
                                </Role>
                                <li>
                                    <Dropdown overlay={groupAccountsMenu(scopedByClient, highlightTab)}>
                                        {/* eslint-disable-next-line */}
                                        <a
                                            className={`${
                                                [
                                                    "groupAccounts",
                                                    "groupAccountBills",
                                                    "groupAccountSubaccounts",
                                                    "groupSupplyAccounts",
                                                    "groupSupplyAccountBills",
                                                    "groupSupplyAccountSubaccounts",
                                                ].indexOf(highlightTab) > -1
                                                    ? "current"
                                                    : ""
                                            } ant-dropdown-link`}
                                            onClick={(e) => e.preventDefault()}>
                                            {/* TODO: Make this a real style */}
                                            Group Billing Accounts <DownOutlined style={{ marginLeft: "10px", marginTop: "3px" }} />
                                        </a>
                                    </Dropdown>
                                </li>
                                <Role action="read" model="bill">
                                    <li>
                                        <Link to={scopedByClient ? `/clients/${scopedByClient}/bills` : "/bills"} className={`${highlightTab === "bills" ? "current" : ""}`}>
                                            Bills
                                        </Link>
                                    </li>
                                </Role>
                                <Role action="read" model="site_survey">
                                    <li>
                                        <Link
                                            to={scopedByClient ? `/clients/${scopedByClient}/ancillary_services/site_surveys` : "/ancillary_services/site_surveys"}
                                            className={`${highlightTab === "ancillaryServices" ? "current" : ""}`}>
                                            Ancillary Services
                                        </Link>
                                    </li>
                                </Role>
                                {clientHasFinancialsAccess(client) && (
                                    <Role action="read" model={["accounting_batch", "payment_batch", "ledger_field", "ledger_account"]}>
                                        <li>
                                            <Dropdown overlay={financialsMenu(scopedByClient, client, highlightTab)}>
                                                {/* eslint-disable-next-line */}
                                                <a className={`${highlightTab === "financials" ? "current" : ""} ant-dropdown-link`} onClick={(e) => e.preventDefault()}>
                                                    Financials <DownOutlined style={{ marginLeft: "10px", marginTop: "3px" }} />
                                                </a>
                                            </Dropdown>
                                        </li>
                                    </Role>
                                )}
                                <Role action="read" model="generated_report">
                                    <li>
                                        <Link to={`/clients/${scopedByClient}/reports/dashboard`} className={`${highlightTab === "reports" ? "current" : ""}`}>
                                            Reporting
                                        </Link>
                                    </li>
                                </Role>
                                <li>
                                    <Dropdown overlay={alertsPanelMenu(scopedByClient, highlightTab)}>
                                        {/* eslint-disable-next-line */}
                                        <a className={`${highlightTab === "alerts" ? "current" : ""} ant-dropdown-link`} onClick={(e) => e.preventDefault()}>
                                            Alerts <DownOutlined style={{ marginLeft: "10px", marginTop: "3px" }} />
                                        </a>
                                    </Dropdown>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>

            <div id="alert-panel">
                <AlertPanel />
            </div>

            <Settings />

            <HeaderDropdown />
            <Role action="read" model="advanced_search">
                { (
                    <>
                        <Link to={clientId ? `/clients/${clientId}/advanced_search` : `/advanced_search`} style={{ position: "absolute", bottom: "-30px", right: 0, paddingRight: "10px" }}>
                            Advanced Search
                        </Link>
                        <Link to={'#'}   onClick={() => window.location.reload()} style={{ position: "absolute", bottom: "-30px", right: 120, paddingRight: "10px" }}>
                            Clear Filters
                        </Link>
                    </>
                )}
            </Role>
        </Layout.Header>
    )
}
