import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch } from "react-router-dom"
import { Table, Tooltip, Skeleton, notification, Dropdown, Menu, Button } from "antd"
import { DeleteOutlined, DownloadOutlined, PlusOutlined, UploadOutlined, EditOutlined, FilterOutlined, SearchOutlined, DownOutlined } from "@ant-design/icons"

import { fetchVendors, selectVendors, selectTotal, selectPagination, selectLoading, filterVendors, generateCsv, deleteVendor } from "../vendorsSlice"
import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnHeaderTitle from "../../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../../functions/objectFunctions/tableFiltersApplied"
import ColumnSearchFilter from "../../../components/utilityComponents/table/ColumnSearchFilter"
import nodePath from "path"
import useConfirmationModal from "../../alertsPanel/ShowConfirm"

export default function VendorTable({ scopedByClient }) {
    /////*** react router start ***/////
    const { url } = useRouteMatch()
    /////*** react router end ***/////

    /////*** redux start ***/////
    const dispatch = useDispatch()

    const vendors = useSelector(selectVendors)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "name",
        direction: "asc",
    })
    /////*** useState end ***/////

    /////*** useEffects start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialVendors()
        }
    }, [loaded, dispatch, pagination]) // eslint-disable-line
    /////*** useEffects end ***/////

    /////*** event handler methods start ***/////
    const handleFetchInitialVendors = () => {
        dispatch(fetchVendors(pagination, nodePath.join(url, "")))
    }

    const handleFilterVendors = (newPagination = null, newSort = null) => {
        return dispatch(
            filterVendors({
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
            })
        )
    }

    const handleSortVendorsChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterVendors(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchVendors(newPagination, nodePath.join(url, "")))
        } else {
            handleFilterVendors(newPagination)
        }
    }
    /////*** event handler methods end ***/////

    const renderColumnHeaderTitle = (title) => {
        return <ColumnHeaderTitle title={title} handleSortChange={handleSortVendorsChange} sort={sort} />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchInitialVendors()
                        setFilters({})
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }
    const showConfirm = useConfirmationModal();
    const handleDeleteClick = (id) => {
        showConfirm(deleteVendor, id);
    };

    const vendorsCsv = () => {
        notification.open({
            message: "Vendors export started",
        })

        dispatch(generateCsv(filters))
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Vendors</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {renderResetFilters()}

                                <Link to="/vendors/new">
                                    <PlusOutlined />
                                    Add New
                                </Link>

                                <Link to={`${url}/import`}>
                                    <PlusOutlined />
                                    Import
                                </Link>

                                <a href="javascript:;" onClick={() => vendorsCsv()}>
                                    <DownloadOutlined />
                                    Export
                                </a>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={vendors} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column
                                title={renderColumnHeaderTitle("name")}
                                key="name"
                                dataIndex="name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialVendors}
                                        filterAction={filterVendors}
                                        filterColumn={"name"}
                                        filterType={"name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("name_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("short_code")}
                                key="short_code"
                                dataIndex="short_code"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialVendors}
                                        filterAction={filterVendors}
                                        filterColumn={"short_code"}
                                        filterType={"short_code_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("short_code_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("banking_identifier")}
                                key="payment_identifier"
                                dataIndex="payment_identifier"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialVendors}
                                        filterAction={filterVendors}
                                        filterColumn={"payment_identifier"}
                                        filterType={"payment_identifier_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("payment_identifier_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("po_box")}
                                key="po_box"
                                dataIndex="po_box"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialVendors}
                                        filterAction={filterVendors}
                                        filterColumn={"po_box"}
                                        filterType={"po_box_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("po_box_cont")}
                            />
                            {/*

                             NOTE: currently on staging no vendors have locations at all because it's not even on the vendor form.
                             For now I'm just commenting out the entry on the table and moving on, it doesn't look like we actually use that.

                             After doing a grep on the admin side "location" is never even used, so I think this column can just go away.

                            <Table.Column
                                title={renderColumnHeaderTitle("location")}
                                key="location"
                                dataIndex="location"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialVendors}
                                        filterAction={filterVendors}
                                        filterColumn={"location"}
                                        filterType={"location_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("location_cont")}
                            />

                            */}

                            {/* <Table.Column title="Services" key="services" dataIndex="services" /> */}
                            <Table.Column title="Payment Methods" key="payment_methods" dataIndex="payment_methods" render={(text, record) => <>{record.payment_methods.map((payment)=>payment.name).join(", ")}</>} />
                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Menu.Item key="1" icon={<EditOutlined />}>
                                                        <Link to={scopedByClient ? `/clients/${scopedByClient}/vendors/${record.id}/edit` : `/vendors/${record.id}/edit`}>Edit Vendor</Link>
                                                    </Menu.Item>
                                                    <Menu.Item key="2" icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)}>
                                                        Delete Vendor
                                                    </Menu.Item>
                                                    {
                                                        scopedByClient && (
                                                            <Menu.Item key="3">
                                                                <Link to={`/clients/${scopedByClient}/vendors/${record.id}/dashboard`}>View</Link>
                                                            </Menu.Item>
                                                        )
                                                    }
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>    
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
