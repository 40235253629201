import React from "react"
import Icon from "@ant-design/icons"
import { useSelector } from "react-redux"

import { ReactComponent as MucLogo } from "../../../assets/images/muc-logo-v2.svg"
import { selectChannelPartnerLogo, selectScopedClient } from "../../../stores/appSlice.js"

const MucLogoSVG = () => {
    return <MucLogo />
}

const GetChannelPartnerLogo = (logo) => {
    return <img src={`${logo}`} alt="logo" className="full-wh" style={{ objectFit: "contain" }} />
}

const GetClientLogo = (logo) => {
    return <img src={`${logo}`} alt="logo" className="full-wh" style={{ objectFit: "contain" }} />
}

function MucLogoHorizontal(props) {
    const channelPartnerLogo = useSelector(selectChannelPartnerLogo)
    const client = useSelector(selectScopedClient)
    const clientLogo = client?.file_url

    if (clientLogo) {
        return <Icon component={() => GetClientLogo(clientLogo)} {...props} />
    } else if (channelPartnerLogo) {
        return <Icon component={() => GetChannelPartnerLogo(channelPartnerLogo)} {...props} />
    } else {
        return <Icon className="full-wh" component={MucLogoSVG} {...props} />
    }
}

export default MucLogoHorizontal
