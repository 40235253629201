    import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, Row, Col, Switch, Select, Skeleton, Space, notification } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import nodePath from "path"
import { isEmpty } from "lodash"
import { states } from "../../../lib/utilities"

import { fetchBillableAccount, setBillableAccount, selectBillableAccount, submitBillableAccount, selectLoading } from "../billableAccountsSlice"
import { fetchClientFacilitySelectOptions, selectFacilitySelectOptions } from "../../facilities/facilitiesSlice"
import { fetchVendorSelectOptions, selectVendorSelectOptions } from "../../vendors/vendorsSlice"
import { fetchUtilityServiceSelectOptions, selectUtilityServiceSelectOptions } from "../../utilityServices/utilityServicesSlice"
import { fetchServiceOptionsForBillableAccount } from "../../services/servicesSlice"

import { requestNotification } from "../../../lib/notifications"

const { Option } = Select

export default function BillableAccountForm({ actionName }) {
    const { url } = useRouteMatch()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { clientId, billableAccountId } = useParams()
    const history = useHistory()
    const loading = useSelector(selectLoading)
    const billableAccount = useSelector(selectBillableAccount)
    const facilitySelectOptions = useSelector(selectFacilitySelectOptions)
    const vendorSelectOptions = useSelector(selectVendorSelectOptions)
    const utilityServiceSelectOptions = useSelector(selectUtilityServiceSelectOptions)
    useEffect(() => {
        if (billableAccountId) {
            dispatch(fetchBillableAccount(billableAccountId))
        } else {
            dispatch(setBillableAccount({}))
        }
    }, [dispatch, billableAccountId])

    useEffect(() => {
        form.resetFields()
        dispatch(fetchClientFacilitySelectOptions(clientId))
        dispatch(fetchVendorSelectOptions())
        dispatch(fetchUtilityServiceSelectOptions())

        // Fetch the Service options on load
        if (billableAccount && billableAccount.utility_service_id) {
            dispatch(fetchServiceOptionsForBillableAccount(clientId, billableAccount.utility_service_id, billableAccount.id))
        }
    }, [form, billableAccount]) //eslint-disable-line

    const handleFacilitySelect = (value) => {
        form.setFieldsValue({ facility_id: value })
    }

    const handleVendorSelect = (value) => {
        form.setFieldsValue({ vendor_id: value })
    }

    const submit = async (values) => {
        const response = await dispatch(submitBillableAccount({ ...values, client_id: clientId }, billableAccountId))

        if (response.success) {
            // If this is an existing billable account, redirect to the billable account table page
            if (billableAccountId) {
                history.push(`/clients/${clientId}/billable_accounts`)
            } else {
                // If this is a new billable account, redirect to the billable account service page
                if (response.id) {
                    history.push(`/clients/${clientId}/billable_accounts/${response.id}/services/new`)
                } else {
                    history.push(`/clients/${clientId}/billable_accounts`)
                }
            }
        }

        requestNotification(response)
    }

    return (
        <div id="user-form-container">
            {/* TODO: Fix back links for scoped by client */}
            <Link to={`/clients/${clientId}/billable_accounts`}>
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} initialValues={!isEmpty(billableAccount) ? billableAccount : { billing_cycle: 30, active: true }} onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <Skeleton active loading={loading}>
                            <div>
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Space>
                                            <Form.Item label="Active" name="active" valuePropName="checked" rules={[{ required: false }]}>
                                                <Switch />
                                            </Form.Item>

                                            <Form.Item label="Group Account" name="is_group_account" valuePropName="checked" rules={[{ required: false }]} >
                                                <Switch disabled={billableAccount.services_count > 0} />
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item label="Facility - Number - Division Name" name="facility_id" rules={[{ required: true }]}>
                                            <Select
                                                placeholder={"Select Facility"}
                                                showArrow={true}
                                                onChange={handleFacilitySelect}
                                                showSearch
                                                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                                {facilitySelectOptions.map((o) => (
                                                    <Option key={o.id} value={o.id} label={o.display_name}>
                                                        {o.display_name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item label="Vendor - Banking Identifier - PO Box - Payment Method" name="vendor_id" rules={[{ required: true }]}>
                                            <Select
                                                placeholder={"Select Vendor"}
                                                showArrow={true}
                                                onChange={handleVendorSelect}
                                                showSearch
                                                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                                {vendorSelectOptions.map((o) => (
                                                    <Option key={o.id} value={o.id} label={o.display_name}>
                                                        {o.display_name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item label="Utility Service" name="utility_service_id" rules={[{ required: false }]}>
                                            <Select placeholder={"Select Utility Service"} showArrow={true}>
                                                {utilityServiceSelectOptions.map((o) => (
                                                    <Option key={o.id} value={o.id}>
                                                        {o.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Account Number" name="account_number" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Tax ID" name="tax_id" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Street Address 1" name="street_address_1" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Street Address 2" name="street_address_2" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item label="City" name="city" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="State" name="state" rules={[{ required: true }]}>
                                            <Select
                                              placeholder={"Select State"}
                                              showArrow={true}
                                              showSearch
                                              filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                                {states.map((o) => (
                                                  <Option key={o} value={o} label={o}>
                                                      {o}
                                                  </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Zipcode" name="zip" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>


                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Mailing Address" name="mailing_address" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Mailing Address Zipcode" name="mailing_zipcode" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Mailing Address City" name="mailing_city" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Mailing Address State" name="mailing_state" rules={[{ required: false }]}>
                                            <Select
                                            placeholder={"Select State"}
                                            showArrow={true}
                                            showSearch
                                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                                {states.map((o) => (
                                                <Option key={o} value={o} label={o}>
                                                    {o}
                                                </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item label="Billing Period" name="billing_period" rules={[{ required: false }]}>
                                            <Select placeholder={"Select Billing Period"} showArrow={true} allowClear={true}>
                                                {[
                                                    { name: "Weekly", val: 0 },
                                                    { name: "Bi-Weekly", val: 1 },
                                                    { name: "Monthly", val: 2 },
                                                    { name: "Quarterly", val: 3 },
                                                ].map((o) => (
                                                    <Option key={o.val} value={o.val}>
                                                        {o.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item label="Billing Cycle" name="billing_cycle" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item label="Notes" name="notes" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {/* Disable for now */}
                                {/* <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item label="Utility Service" name="utility_service_id">
                                            <Select placeholder={"Select Utility Service"} showArrow={true} showSearch onChange={handleUtilityServiceSelect}>
                                                {utilityServiceSelectOptions.map((o) => (
                                                    <Option key={o.id} value={o.id}>
                                                        {o.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={16}>
                                        <Form.Item label="Linked Supply Services" name="supply_service_ids">
                                            <Select placeholder={"Select Services"} showArrow={true} onChange={handleServicesSelect} mode="multiple" allowClear>
                                                {serviceSelectOptions.map((o) => (
                                                    <Option key={o.id} value={o.id}>
                                                        {o.id_number}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row> */}

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </div>
                        </Skeleton>
                    </div>
                </div>
            </Form>
        </div>
    )
}
