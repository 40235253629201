import React from "react"
import { Table, Button } from "antd"
import { DownloadOutlined } from "@ant-design/icons"
import moment from "moment"
import Role from "../../userRoles/Role"
import WarningChip from "./subcomponents/WarningChip"
import BillAccountLink from "../../../components/utilityComponents/linkGenerators/BillAccountLink"
import { formatCurrency } from "../../../lib/utilities"

export default function BillTable({ data }) {
    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <Table dataSource={data} rowKey={(r) => r.id} pagination={false}>
                        <Table.Column
                            title=" "
                            key="flags"
                            dataIndex="flags"
                            render={(flags, record) => <WarningChip contentText={Object.values(flags)} flagCount={Object.values(flags).length} childFlags={record.any_child_flags} />}
                            width={50}
                        />
                        <Table.Column title="Vendor" key="vendor_name" dataIndex="vendor_name" />
                        <Table.Column title="Client Batch" key="accounting_batch_batch_code" dataIndex="accounting_batch_batch_code" />
                        <Table.Column title="Date" key="date" dataIndex="date" render={(text, record, index) => moment(text).format("L")} />
                        <Table.Column title="Due Date" key="due_date" dataIndex="due_date" render={(text, record, index) => moment(text).format("L")} />
                        <Table.Column title="Read Date" key="read_date" dataIndex="read_date" render={(text, record, index) => moment(text).format("L")} />
                        <Table.Column title="Total Usage" key="total_usage" dataIndex="total_usage" align='right' />
                        <Table.Column title="Balance Forward" key="balance_forward" dataIndex="balance_forward" align="right" render={(text, record, index) => formatCurrency(text)} />
                        <Table.Column title="Current Charges" key="current_charges" dataIndex="current_charges" align="right" render={(text, record, index) => formatCurrency(text)} />
                        <Table.Column title="Late Fee" key="late_fee" dataIndex="late_fee" align="right" render={(text, record, index) => formatCurrency(text)} />
                        <Table.Column title="Amount Due" key="amount_due" dataIndex="amount_due" align="right" render={(text, record, index) => formatCurrency(text)} />
                        <Role action="read" model="bill_notes">
                            <Table.Column title="Notes" key="notes" dataIndex="notes" />
                        </Role>
                        <Table.Column
                            title="Copy"
                            key="copy"
                            render={(text, record) => (
                                <>
                                    {record.file_url && (
                                        <Button type="primary" size="small" onClick={() => window.open(record.file_url, "_blank")}>
                                            <DownloadOutlined />
                                        </Button>
                                    )}
                                </>
                            )}
                        />
                    </Table>
                </div>
            </div>
        </div>
    )
}
