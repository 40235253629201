import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Alert, Button, Form, Input, Row, Col, Select, Switch, DatePicker, InputNumber } from "antd"
import { MinusCircleOutlined } from "@ant-design/icons"
import moment from "moment"
import { isEmpty, includes } from "lodash"
import { roundHumanizedNumbers } from "../../../lib/utilities"

import { fetchlinkedServiceBillSelectOptions, fetchServiceBill, setLinkedServiceBillSelectOptions, selectLinkedServiceBillOptions, selectPreviousBill, selectAccount } from "../billsSlice"
import WarningChip from "./subcomponents/WarningChip"
import ServiceBillHistory from "./subcomponents/ServiceBillHistory"
import DateMask from "../../forms/components/DateMask"

const { Option } = Select
const { TextArea } = Input
const dateFormat = "MM/DD/YYYY"

export default function LinkedServiceForm({ bill, form, accountId, initialValue }) {
    const dispatch = useDispatch()
    const { billId } = useParams()
    const linkedServiceBillOptions = useSelector(selectLinkedServiceBillOptions)
    const [selectedServiceBill, setSelectedServiceBill] = useState(null)
    const [selectedLinkedServices, setSelectedLinkedServices] = useState([])
    const previousBill = useSelector(selectPreviousBill)
    const account = useSelector(selectAccount)


    useEffect(() => {
        if (accountId) {
            dispatch(fetchlinkedServiceBillSelectOptions(accountId))
        } else {
            dispatch(setLinkedServiceBillSelectOptions([]))
        }
    }, [dispatch, accountId])

    // Store the initial linked_service_bill ids to disable/enable options in the drop down
    useEffect(() => {
        if (initialValue && !isEmpty(initialValue.linked_service_bills_attributes)) {
            setSelectedLinkedServices(initialValue.linked_service_bill_attributes.map((o) => o.id))
        }
    }, [initialValue])

    const handleServiceBillSelect = (value) => {
        setSelectedServiceBill(value)
    }

    const addLinkedService = async () => {
        if (!includes(selectedLinkedServices, selectedServiceBill)) {
            const response = await dispatch(fetchServiceBill(selectedServiceBill))
            if (response.success && !isEmpty(response.service_bill)) {
                setSelectedLinkedServices([...selectedLinkedServices, selectedServiceBill])
                // Check if we already have linked_service_bill so that we append to existing list
                if (!isEmpty(form.getFieldValue("linked_service_bills_attributes"))) {
                    form.setFieldsValue({
                        linked_service_bills_attributes: [
                            ...form.getFieldValue("linked_service_bills_attributes"),
                            {
                                ...response.service_bill,
                                fiscal_month: moment(response.service_bill.fiscal_month || new Date()),
                                next_read_date: moment(response.service_bill.next_read_date || new Date()),
                                previous_read_date: moment(response.service_bill.previous_read_date || new Date()),
                                read_date: moment(response.service_bill.read_date || new Date()),
                                service_bill_usage_factors_attributes: response.service_bill.service_bill_usage_factors.map((service_bill_usage_factor) => {
                                    return {
                                        ...service_bill_usage_factor,
                                    }
                                }),
                                service_bill_entry_fields_attributes: response.service_bill.service_bill_entry_fields.map((service_bill_entry_field) => {
                                    return {
                                        ...service_bill_entry_field,
                                    }
                                }),
                                service_bill_additional_entry_fields_attributes: response.service_bill.service_bill_additional_entry_fields.map((service_bill_additional_entry_field) => {
                                    return {
                                        ...service_bill_additional_entry_field,
                                    }
                                }),
                            },
                        ],
                    })
                    // No linked_service_bills so we can just create the object
                } else {
                    form.setFieldsValue({
                        linked_service_bills_attributes: [
                            {
                                ...response.service_bill,
                                fiscal_month: moment(response.service_bill.fiscal_month || new Date()),
                                next_read_date: moment(response.service_bill.next_read_date || new Date()),
                                previous_read_date: moment(response.service_bill.read_date || new Date()),
                                read_date: moment(response.service_bill.read_date || new Date()),
                                service_bill_usage_factors_attributes: response.service_bill.service_bill_usage_factors.map((service_bill_usage_factor) => {
                                    return {
                                        ...service_bill_usage_factor,
                                    }
                                }),
                                service_bill_entry_fields_attributes: response.service_bill.service_bill_entry_fields.map((service_bill_entry_field) => {
                                    return {
                                        ...service_bill_entry_field,
                                    }
                                }),
                                service_bill_additional_entry_fields_attributes: response.service_bill.service_bill_additional_entry_fields.map((service_bill_additional_entry_field) => {
                                    return {
                                        ...service_bill_additional_entry_field,
                                    }
                                }),
                            },
                        ],
                    })
                }
            }
        }
    }

    // A Service Bill option is disabled if it has already been added
    // We can check against the local state of ids.
    const isDisabled = (selectedLinkedServices, id) => {
        return selectedLinkedServices.includes(id)
    }

    const showRemoveButton = (index) => {
        return !form.getFieldValue("linked_service_bills_attributes")[index].supply_bill_id
    }

    const removeLinkedService = (removeFnc, index) => {
        let currentlyLinked = selectedLinkedServices
        currentlyLinked.splice(index, 1)
        setSelectedLinkedServices([...currentlyLinked])
        removeFnc()
    }

    return (
        <>
            <Row gutter={24}>
                <Col span={8}>
                    <Select placeholder={"Select"} showArrow={true} onChange={handleServiceBillSelect} style={{ width: "100%" }} disabled={!isEmpty(bill) && bill.finalized}>
                        {linkedServiceBillOptions.map((o) => (
                            <Option key={o.id} value={o.id} disabled={isDisabled(selectedLinkedServices, o.id)}>
                                {o.full_name}
                            </Option>
                        ))}
                    </Select>
                </Col>

                <Col span={8}>
                    <Button type="primary" onClick={addLinkedService} disabled={!isEmpty(bill) && bill.finalized}>
                        Add Linked Service Bill
                    </Button>
                </Col>
            </Row>

            {/* remove this lol */}
            <br />
            <Form.List name="linked_service_bills_attributes" initialValue={initialValue}>
                {(fields, { remove }, {}) => (
                    <>
                        {fields.map((field, index) => {
                            let accountService, meterText
                            const formServiceBill = form.getFieldValue("linked_service_bills_attributes")[field.fieldKey]
                            
                            try {
                                accountService = selectedLinkedServices.filter((s) => s.id === formServiceBill.service_id)[0]

                                const prevServiceBill =
                                    !isEmpty(previousBill) && previousBill.service_bills.filter((sb) => sb.service_id === formServiceBill.service_id)[0]

                                meterText = (
                                    <div>
                                        {`Service/Meter ${formServiceBill?.service_utility_service_name} - ${formServiceBill?.service_meter_number}`}

                                        {
                                            <span style={{ float: "right" }}>
                                                {prevServiceBill?.usage
                                                    ? `Previous Usage Was ${roundHumanizedNumbers(prevServiceBill?.usage)} ${prevServiceBill?.unit_symbol} From ${moment(
                                                          prevServiceBill?.read_date
                                                      ).format("L")} Reading of ${prevServiceBill?.meter_reading}`
                                                    : "No Record Of Usage From Previous Bill For This Service"}
                                            </span>
                                        }
                                    </div>
                                )
                            } catch (e) {
                                console.warn("Could not find account service: ", e)
                            }
                            return (
                                <React.Fragment key={index}>
                                    <Alert message={meterText || "ERROR"} type="info" />

                                    {/* Only allow removing linked services that don't already exist */}
                                    {showRemoveButton(index) && <MinusCircleOutlined className="dynamic-delete-button" onClick={() => removeLinkedService(() => remove(field.name), index)} />}

                                    <Form.Item {...field} name={[field.name, "service_id"]} fieldKey={[field.fieldKey, "service_id"]} hidden={true} />

                                    <Row gutter={24}>
                                        <Col span={6}>
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <WarningChip contentText={billId ? bill.linked_service_bills[field.fieldKey]?.flags?.previous_read_date_flag : ""} />
                                                    <Form.Item
                                                        {...field}
                                                        label="Prev Read Date"
                                                        name={[field.name, "previous_read_date"]}
                                                        fieldKey={[field.fieldKey, "previous_read_date"]}
                                                        rules={[{ required: true, message: "Required" }]}>
                                                        <DatePicker format={dateFormat} style={{ width: "100%" }} disabled={!isEmpty(bill) && bill.finalized} />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item
                                                        {...field}
                                                        label="Read Date"
                                                        name={[field.name, "read_date"]}
                                                        fieldKey={[field.fieldKey, "read_date"]}
                                                        rules={[{ required: true, message: "Required" }]}>
                                                        <DatePicker format={dateFormat} style={{ width: "100%" }} disabled={!isEmpty(bill) && bill.finalized} />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item
                                                        {...field}
                                                        label="Next Read Date"
                                                        name={[field.name, "next_read_date"]}
                                                        fieldKey={[field.fieldKey, "next_read_date"]}
                                                        rules={[{ required: false, message: "Required" }]}>
                                                        <DatePicker format={dateFormat} style={{ width: "100%" }} disabled={!isEmpty(bill) && bill.finalized} />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item
                                                        {...field}
                                                        label="Fiscal Month"
                                                        name={[field.name, "fiscal_month"]}
                                                        fieldKey={[field.fieldKey, "fiscal_month"]}
                                                        rules={[{ required: false, message: "Required" }]}>
                                                        <DatePicker format={dateFormat} style={{ width: "100%" }} disabled={!isEmpty(bill) && bill.finalized} />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <WarningChip contentText={billId ? bill.linked_service_bills[field.fieldKey]?.flags?.number_of_days_flag : ""} />
                                                    <Form.Item
                                                        {...field}
                                                        label="# of Days"
                                                        name={[field.name, "number_of_days"]}
                                                        fieldKey={[field.fieldKey, "number_of_days"]}
                                                        rules={[{ required: false, message: "Required" }]}>
                                                        <Input disabled={!isEmpty(bill) && bill.finalized} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col span={6}>
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <WarningChip contentText={billId ? bill.linked_service_bills[field.fieldKey]?.flags?.previous_reading_flag : ""} />
                                                    <Form.Item {...field} label="Prev Reading" name={[field.name, "previous_reading"]} fieldKey={[field.fieldKey, "previous_reading"]}>
                                                        <InputNumber disabled={!isEmpty(bill) && bill.finalized} style={{ width: "100%" }} precision={2} />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item {...field} label="Meter Reading" name={[field.name, "meter_reading"]} fieldKey={[field.fieldKey, "meter_reading"]}>
                                                        <Input disabled={!isEmpty(bill) && bill.finalized}  style={{ width: "100%" }} precision={2}  />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <WarningChip contentText={billId ? bill.linked_service_bills[field.fieldKey]?.flags?.multiplier_flag : ""} />
                                                    <Form.Item {...field} label="multiplier" name={[field.name, "multiplier"]} fieldKey={[field.fieldKey, "multiplier"]}>
                                                        <Input disabled={!isEmpty(bill) && bill.finalized}  style={{ width: "100%" }} precision={3} />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <WarningChip contentText={billId ? bill.linked_service_bills[field.fieldKey]?.flags?.usage_flag : ""} />
                                                    <Form.Item {...field} label="Usage" name={[field.name, "usage"]} fieldKey={[field.fieldKey, "usage"]} rules={[{ required: true, message: "Required" }]}>
                                                        <Input disabled={!isEmpty(bill) && bill.finalized}  style={{ width: "100%" }} precision={3} />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12}>
                                                    <Form.Item {...field} label="Unit" name={[field.name, "unit_id"]} fieldKey={[field.fieldKey, "unit_id"]}>
                                                        <Select showArrow={true} disabled={!isEmpty(bill) && bill.finalized}>
                                                            {form.getFieldValue("linked_service_bills_attributes")[index].unit_options.map((o) => (
                                                                <Option key={o.id} value={o.id}>
                                                                    {o.symbol}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12}>
                                                    <WarningChip contentText={billId ? bill.linked_service_bills[field.fieldKey]?.flags?.estimated_flag : ""} />
                                                    <Form.Item {...field} label="estimated" name={[field.name, "estimated"]} valuePropName="checked" fieldKey={[field.fieldKey, "estimated"]}>
                                                        <Switch disabled={!isEmpty(bill) && bill.finalized} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col span={6}>
                                            <Col span={24}>
                                                <Form.Item noStyle={true}>
                                                    <Form.List name={[field.name, "service_bill_entry_fields_attributes"]}>
                                                        {(service_bill_entry_fields_attributes, {}) => {
                                                            return (
                                                                <div>
                                                                    {service_bill_entry_fields_attributes.map((entryField, key2) => (
                                                                        <Form.Item
                                                                            {...entryField}
                                                                            label={form.getFieldValue("linked_service_bills_attributes")[index].service_bill_entry_fields_attributes[key2].name}
                                                                            name={[entryField.name, "value"]}
                                                                            fieldKey={[entryField.fieldKey, "value"]}
                                                                            key={key2}
                                                                            rules={[
                                                                                {
                                                                                    required:
                                                                                        form.getFieldValue("linked_service_bills_attributes")[index].service_bill_entry_fields_attributes[key2].name ===
                                                                                        "Supply Charges",
                                                                                    message: "Supply Charges are required",
                                                                                },
                                                                            ]}>
                                                                            <InputNumber disabled={!isEmpty(bill) && bill.finalized} style={{ width: "100%" }} precision={2} />
                                                                        </Form.Item>
                                                                    ))}
                                                                </div>
                                                            )
                                                        }}
                                                    </Form.List>
                                                </Form.Item>
                                            </Col>
                                        </Col>

                                        <Col span={6}>
                                            <Row gutter={24}>
                                                <Col span={24} style={{ marginBottom: "0" }}>
                                                    <Form.Item noStyle={true}>
                                                        <Form.List name={[field.name, "service_bill_usage_factors_attributes"]}>
                                                            {(service_bill_usage_factors_attributes, {}) => {
                                                                return (
                                                                    <div>
                                                                        {service_bill_usage_factors_attributes.map((usageFactor, key2) => (
                                                                            <Form.Item
                                                                                {...usageFactor}
                                                                                label={form.getFieldValue("linked_service_bills_attributes")[index].service_bill_usage_factors_attributes[key2].name}
                                                                                name={[usageFactor.name, "value"]}
                                                                                fieldKey={[usageFactor.fieldKey, "value"]}
                                                                                key={key2}
                                                                                rules={[{ required: true, message: "Required" }]}>
                                                                                <InputNumber style={{ width: "100%" }} precision={4} />
                                                                            </Form.Item>
                                                                        ))}
                                                                    </div>
                                                                )
                                                            }}
                                                        </Form.List>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item noStyle={true}>
                                                        <Form.List name={[field.name, "service_bill_additional_entry_fields_attributes"]}>
                                                            {(service_bill_additional_entry_fields_attributes, {}) => {
                                                                return (
                                                                    <div>
                                                                        {service_bill_additional_entry_fields_attributes.map((additionalEntryField, key2) => (
                                                                            <Form.Item
                                                                                {...additionalEntryField}
                                                                                label={
                                                                                    form.getFieldValue("linked_service_bills_attributes")[index].service_bill_additional_entry_fields_attributes[key2].name
                                                                                }
                                                                                name={[additionalEntryField.name, "value"]}
                                                                                fieldKey={[additionalEntryField.fieldKey, "value"]}
                                                                                key={key2}
                                                                                rules={[{ required: true, message: "Required" }]}>
                                                                                <InputNumber disabled={!isEmpty(bill) && bill.finalized} style={{ width: "100%" }} precision={4} />
                                                                            </Form.Item>
                                                                        ))}
                                                                    </div>
                                                                )
                                                            }}
                                                        </Form.List>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        {...field}
                                                        label="Notes"
                                                        name={[field.name, "notes"]}
                                                        fieldKey={[field.fieldKey, "notes"]}>
                                                        <TextArea
                                                            style={{ width: "100%" }}
                                                            rows="3"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <ServiceBillHistory serviceId={form.getFieldValue("linked_service_bills_attributes")[index].service_id} />
                                </React.Fragment>
                            )
                        })}
                    </>
                )}
            </Form.List>
        </>
    )
}
