import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, Row, Col, Select } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import { notification } from 'antd'
import { states } from "../../../lib/utilities"
import { fetchVendor, setVendor, selectVendor, submitVendor } from "../vendorsSlice"
import { fetchPaymentMethods, selectPaymentMethods, selectPagination } from "../paymentMethods/paymentMethodsSlice"
import nodePath from "path"

const { Option } = Select

export default function VendorForm() {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const vendor = useSelector(selectVendor)
    const payment_methods = useSelector(selectPaymentMethods)
    const pagination = useSelector(selectPagination)
    const { vendorId } = useParams()
    const history = useHistory()
    const { url } = useRouteMatch()

    useEffect(() => {
        if (vendorId) {
            dispatch(fetchVendor(vendorId))
        } else {
            dispatch(setVendor({}))
        }
        dispatch(fetchPaymentMethods(pagination, nodePath.join(url, "")))
    }, [dispatch, vendorId])

    useEffect(() => {
        form.resetFields()
    }, [form, vendor])

    const handleServicesSelect = (value) => {
        form.setFieldsValue({ services: value })
    }

    const submit = async (values) => {
        const response = await dispatch(submitVendor(values))

        if (response.success) {
            history.push("/vendors")
        } else {
            // show error when response is not successful... probably should be a global axios hook
            notification.error({
                message: "Request error",
                description: response.message
            })
        }
    }

    return (
        <div id="user-form-container">
            <Link to="/vendors">
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} initialValues={vendor} onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <div>
                            <Row gutter={16} align="center">
                                <Col span={8}>
                                    <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Short Code" name="short_code" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} align="center">
                                <Col span={8}>
                                    <Form.Item label="Street Address 1" name="street_address_1" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Street Address 2" name="street_address_2" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} align="center">
                                <Col span={8}>
                                    <Form.Item label="Phone" name="phone" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="City" name="city" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} align="center">
                                <Col span={8}>
                                    <Form.Item label="State" name="state" rules={[{ required: false }]}>
                                        <Select
                                            placeholder={"Select State"}
                                            showArrow={true}
                                            showSearch
                                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                            {states.map((o) => (
                                                <Option key={o} value={o} label={o}>
                                                    {o}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Zipcode" name="zip" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} align="center">
                                <Col span={8}>
                                    <Form.Item label="Po Box" name="po_box" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Tariffs link" name="tariffs_link" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} align="center">
                                <Col span={8}>
                                    <Form.Item label="Payment methods" name="payment_method_ids">
                                        <Select placeholder={"Please select available payment methods"} defaultValue={vendor.payment_methods?.map((payment) => payment.id)}  showArrow={true} allowClear>
                                            {payment_methods.map((o) => (
                                                <Option key={o.id} value={o.id}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Banking Identifier" name="payment_identifier" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} align="center">
                                <Col span={16} align="center">
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
