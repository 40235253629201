import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, Row, Col, Switch, Select, Space, notification } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import nodePath from "path"
import { isEmpty } from "lodash"

import { fetchSupplyAccount, setSupplyAccount, selectSupplyAccount, submitSupplyAccount } from "../supplyAccountsSlice"
import { fetchClientFacilitySelectOptions, selectFacilitySelectOptions } from "../../facilities/facilitiesSlice"
import { fetchVendorSelectOptions, selectVendorSelectOptions } from "../../vendors/vendorsSlice"
import { fetchUtilityServiceSelectOptions, selectUtilityServiceSelectOptions } from "../../utilityServices/utilityServicesSlice"
import { fetchServiceOptionsForSupplyAccount, selectServiceOptionsForSupplyAccount } from "../../services/servicesSlice"

import { requestNotification } from "../../../lib/notifications"
import {states} from "../../../lib/utilities";

const { Option } = Select

export default function SupplyAccountForm({ actionName }) {
    const { url } = useRouteMatch()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { clientId, supplyAccountId } = useParams()
    const history = useHistory()
    const supplyAccount = useSelector(selectSupplyAccount)
    const facilitySelectOptions = useSelector(selectFacilitySelectOptions)
    const vendorSelectOptions = useSelector(selectVendorSelectOptions)
    const utilityServiceSelectOptions = useSelector(selectUtilityServiceSelectOptions)
    const serviceSelectOptions = useSelector(selectServiceOptionsForSupplyAccount)
    useEffect(() => {
        if (supplyAccountId) {
            dispatch(fetchSupplyAccount(supplyAccountId))
        } else {
            dispatch(setSupplyAccount({}))
        }
    }, [dispatch, supplyAccountId])

    useEffect(() => {
        form.resetFields()
        dispatch(fetchClientFacilitySelectOptions(clientId))
        dispatch(fetchVendorSelectOptions())
        dispatch(fetchUtilityServiceSelectOptions())

        // Fetch the Service options on load
        if (supplyAccount && supplyAccount.utility_service_id) {
            dispatch(fetchServiceOptionsForSupplyAccount(clientId, supplyAccount.utility_service_id))
        }
    }, [form, supplyAccount]) //eslint-disable-line

    const handleFacilitySelect = (value) => {
        form.setFieldsValue({ facility_id: value })
    }

    const handleVendorSelect = (value) => {
        form.setFieldsValue({ vendor_id: value })
    }

    const handleUtilityServiceSelect = (value) => {
        form.setFieldsValue({ utility_service_id: value })
        form.setFieldsValue({ billable_service_ids: [] })
        dispatch(fetchServiceOptionsForSupplyAccount(clientId, value))
    }

    const submit = async (values) => {
        const response = await dispatch(submitSupplyAccount({ ...values, client_id: clientId }))

        if (response.success) {
            actionName === "edit" ? history.push(`${nodePath.join(url, "../..")}`) : history.push(`${nodePath.join(url, "..")}`)
        }

        requestNotification(response)
    }

    const initialValues = () => {
        const defaultBilling = 2 // 2 is monthly

        if (!isEmpty(supplyAccount)) {
            return {
                ...supplyAccount,
                billing_period: supplyAccount.billing_period || defaultBilling,
                billable_service_ids: supplyAccount.service_identifiers.map(service => service[0])
            }
        } else {
            return {
                billing_cycle: 30,
                active: true,
                billing_period: defaultBilling,
            }
        }
    }

    return (
        <div id="user-form-container">
            {/* TODO: Fix back links for scoped by client */}
            <Link to={`/clients/${clientId}/supply_accounts`}>
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} initialValues={initialValues()} onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <div>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Space>
                                        <Form.Item label="Active" name="active" valuePropName="checked" rules={[{ required: false }]}>
                                            <Switch />
                                        </Form.Item>

                                        <Form.Item label="Group Account" name="is_group_account" valuePropName="checked" rules={[{ required: false }]}>
                                            <Switch />
                                        </Form.Item>
                                    </Space>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Facility - Number - Division Name" name="facility_id">
                                        <Select
                                            placeholder={"Select Facility"}
                                            showArrow={true}
                                            onChange={handleFacilitySelect}
                                            showSearch
                                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                            {facilitySelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id} label={o.display_name}>
                                                    {o.display_name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Vendor - Banking Identifier - PO Box - Payment Method" name="vendor_id">
                                        <Select
                                            placeholder={"Select Vendor"}
                                            showArrow={true}
                                            onChange={handleVendorSelect}
                                            showSearch
                                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                            {vendorSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id} label={o.display_name}>
                                                    {o.display_name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Disabled on 5/12/22 until further notice */}
                            {/* <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label="Track Supply Inventory" name="track_supply_inventory" valuePropName="checked" rules={[{ required: false }]}>
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row> */}

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Account Number" name="account_number" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                {/* <Col span={8}>
                                    <Form.Item label="Tax ID" name="tax_id" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col> */}
                            </Row>

                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Street Address 1" name="street_address_1" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Street Address 2" name="street_address_2" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="City" name="city" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="State" name="state" rules={[{ required: true }]}>
                                        <Select
                                          placeholder={"Select State"}
                                          showArrow={true}
                                          // onChange={handleVendorSelect}
                                          showSearch
                                          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                            {states.map((o) => (
                                              <Option key={o} value={o} label={o}>
                                                  {o}
                                              </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Zipcode" name="zip" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Mailing Address" name="mailing_address" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Mailing Address Zipcode" name="mailing_zipcode" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Mailing Address City" name="mailing_city" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Mailing Address State" name="mailing_state" rules={[{ required: false }]}>
                                            <Select
                                            placeholder={"Select State"}
                                            showArrow={true}
                                            showSearch
                                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                                {states.map((o) => (
                                                <Option key={o} value={o} label={o}>
                                                    {o}
                                                </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Billing Period" name="billing_period" rules={[{ required: false }]}>
                                        <Select placeholder={"Select Billing Period"} showArrow={true} allowClear={true}>
                                            {[
                                                { name: "Weekly", val: 0 },
                                                { name: "Bi-Weekly", val: 1 },
                                                { name: "Monthly", val: 2 },
                                                { name: "Quarterly", val: 3 },
                                            ].map((o) => (
                                                <Option key={o.val} value={o.val}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Billing Cycle" name="billing_cycle" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Notes" name="notes" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Utility Service" name="utility_service_id">
                                        <Select placeholder={"Select Utility Service"} showArrow={true} showSearch onChange={handleUtilityServiceSelect}
                                                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                            {utilityServiceSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id} label={o.name}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={16}>
                                    <Form.Item label="Linked Billable Account Services" name="billable_service_ids">
                                        <Select placeholder={"Select Services"} showArrow={true} mode="multiple" allowClear
                                                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                                            {serviceSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id} label={o.service_identifier}>
                                                    {o.service_identifier}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
